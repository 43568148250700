import React from "react";
import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import RequirementsSection from "../components/sections/RequirementsSection";
import Footer from "../components/sections/Footer";
import InfoSection from "../components/sections/InfoSection";
import RequirementItem from "../components/elements/RequirementItem";

const RequirementsSePage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"AxxPay Krav"} description={``} />
    <Header lang="se" />
    <RequirementsSection
      title="Terminal app Android OS Krav"
      description="Enheten som appen kommer att distribueras på måste stödja följande som ett minimum och hålla jämna steg med MPP-uppdateringar:"
    >
      <RequirementItem
        title=""
        description=""
        imgName="axxpay-image.png"
        features={[
          {
            name: "Android OS version upp till 8",
            description:
              "Minimikrav måste bibehållas när säker- hetsstandarder uppdateras. Det kommer att meddelas när denna version behöver uppgraderas",
            icon: GlobeAltIcon,
          },
          {
            name: "Manufacturer",
            description:
              "Tillverkaren är listad som en Google Play Protect-partner: https://www.android.com/certified/partners/#tab-panel-brands",
            icon: ScaleIcon,
          },
          {
            name: "Följande källor accepteras för att definiera enheter som stöds:",
            description:
              "https://security.samsungmobile.com/workScope.smsb  https://www.android.com/one/ https://lgsecurity.lge.com/security_updates.html",
            icon: AnnotationIcon,
          },
          {
            name: "SafetyNet-kontroller",
            description:
              "Enheten klarar SafetyNet-kontroller: https://developer.android.com/training/safetynet",
            icon: AnnotationIcon,
          },
        ]}
      />
      <RequirementItem
        title=""
        description=""
        reverse={true}
        imgName="axxpay_image_2.png"
        features={[
          {
            name: "Enheten har ett hårdvarustött nyckellager",
            description: "",
            icon: LightningBoltIcon,
          },
          {
            name: "Platstjänster aktiverade",
            description: "",
            icon: AnnotationIcon,
          },
          {
            name: "Enheten får säkerhetsuppdateringar minst två gånger om året",
            description: "",
            icon: AnnotationIcon,
          },
          {
            name: "Tillgång till telefontjänster och filsystem",
            description: "",
            icon: AnnotationIcon,
          },
        ]}
      />
    </RequirementsSection>
    <InfoSection
      title="Enheter som inte stöds"
      imgName=""
      text={[
        "En enhet kommer inte att stödjas om den inte längre tar emot säker- hetsuppdateringar från tillverkaren eller annan relevant part.",
        "Beroende på problemet kommer det att finnas en respitperiod** under vilken nya enheter av samma märke och modell inte kommer att tillåtas, men befintliga enheter kan fasas ut under en period på högst tre månader.",
        "**Längden på fristen avgörs från fall till fall.",
      ]}
    />
    <Footer lang="se" />
  </div>
);

export default RequirementsSePage;
